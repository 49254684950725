<template>
  <div class="d-flex flex-column flex-md-row m-0 bg-neutral-dark">
    <div class="d-flex flex-column col-12 p-0 order-3">
      <div
        class="mb-5 py-4 px-4 px-md-5 bg-neutral-dark z-200"
        style="position: sticky; top: 0; border-bottom: 2px solid var(--grey)"
      >
        <div
          class="d-flex w-100 justify-content-between align-items-center mb-3"
        >
          <h1 class="text-capitalize text-neutral-light font-weight-medium">
            {{ $store.state.project.name }}
          </h1>
          <div class="d-flex gap-3 align-items-center">
            <router-link
              :to="$store.state.project.isWizard ? 'Wizard' : 'Dashboard'"
            >
              <button
                class="border-neutral-light rounded-button m-0 px-4 py-2 py-md-3"
              >
                <h4 class="text-white font-weight-500 m-0">
                  Back to
                  {{ $store.state.project.isWizard ? "wizard" : "dashboard" }}
                </h4>
              </button>
            </router-link>

            <a href="https://space7.co/checkout">
              <button class="bg-blue-1 rounded-button m-0 px-4 py-2 py-md-3">
                <h4 class="text-white font-weight-500 m-0">Checkout</h4>
              </button>
            </a>
          </div>
        </div>

        <div class="d-flex w-100 justify-content-between align-items-center">
          <div
            class="d-flex max-content justify-content-between justify-content-md-start align-items-end mt-3"
          >
            <button
              class="cart__sort bg-neutral-dark rounded-button m-0 px-4 py-2 py-md-3 me-md-3"
              :class="{ 'cart__sort-active': isSortByRoom }"
              @click="isSortByRoom = true"
            >
              <h4 class="font-weight-500 m-0">Sort By Space</h4>
            </button>

            <button
              class="cart__sort bg-neutral-dark rounded-button m-0 px-4 py-2 py-md-3"
              :class="{ 'cart__sort-active': !isSortByRoom }"
              @click="isSortByRoom = false"
            >
              <h4 class="font-weight-500 m-0">Sort By System</h4>
            </button>
          </div>
          <h2 class="text-neutral-light d-block font-weight-medium mt-1 mb-0">
            <span class="text-primary-light">Total</span> EGP
            {{
              project
                ? addCommasToNumber(parseFloat(project.total).toFixed(2))
                : 0
            }}
          </h2>
        </div>
      </div>

      <div v-if="isSortByRoom">
        <div
          class="px-4"
          v-for="(room, index) in rooms.filter((r) => r.systems)"
          :key="index"
        >
          <cart-card
            v-if="room.price > 0"
            :expanded="room.expanded"
            :name="room.name"
            :total-price="room.price"
            :sub-categories="room.systems"
            :icon="room.icon"
            @click.native="room.expanded = !room.expanded"
            @delete="deleteAnswers"
            @edit="editAnswer"
          />
        </div>
      </div>
      <div v-else>
        <div class="px-4" v-for="(system, index) in systems" :key="index">
          <cart-card
            v-if="system.price > 0"
            :key="index"
            :name="system.name"
            :icon="system.icon"
            :expanded="system.expanded"
            :sub-categories="system.rooms"
            :total-price="system.price"
            @click.native="system.expanded = !system.expanded"
            @delete="deleteAnswers"
            @edit="editAnswer"
          />
        </div>
      </div>
    </div>
    <modal @close="showOverlay = false" :show="showOverlay">
      <wizard
        class="h-100 wd-90p mx-auto"
        :limited-question-ids="limitedQuestionIds"
      />
    </modal>
    <modal
      @close="showDescriptionOverlay = false"
      :show="showDescriptionOverlay"
    >
      <div
        class="wd-50p bg-white p-3 px-md-5 py-md-5"
        style="overflow-y: scroll"
      >
        <div v-for="(system, index) in systems" :key="index">
          <h4>{{ system.name }}</h4>
          <img
            class="w-100 mb-3"
            :src="require('@/assets/images/compound-bg.jpeg')"
            style="
              height: 150px;
              object-fit: cover;
              object-position: center;
              border-radius: 0.25rem;
            "
          />
          <h6>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
            malesuada placerat ante, quis venenatis dolor elementum eu.
            Vestibulum nec nunc elementum, ultrices est vel, fermentum nunc.
            Morbi id nisi odio. Duis congue sollicitudin lacus, eu sagittis
            magna imperdiet eget.
          </h6>

          <a href="https://www.fibaro.com">
            <h6 class="mt-3 text-neutral-dark">www.fibaro.com</h6>
          </a>
          <hr
            style="
              background: var(--neutral-dark) !important;
              height: 2px;
              opacity: 1 !important;
            "
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import CartCard from "@/components/CartCard";
import { categories } from "@/utils/constants";
import Wizard from "@/views/Wizard";
import Modal from "@/components/Modal";
import numberFormat from "@/mixins/numberFormat";

export default {
  name: "project",
  components: { Modal, Wizard, CartCard },
  mixins: [numberFormat],
  data: function () {
    return {
      isSortByRoom: true,
      selectedFilterIndex: false,
      rooms: [],
      systems: [],
      project: null,
      categories: categories,
      showOverlay: false,
      limitedQuestionIds: [],
      showDescriptionOverlay: true,
    };
  },
  mounted() {
    this.getProjectData();
  },
  methods: {
    getProjectData() {
      this.getByRooms();
      this.getBySystems();
    },
    getByRooms() {
      this.$store.dispatch("project/getProjectSummary", false).then((data) => {
        this.project = data.data;
        this.rooms = this.initializeRooms(this.project.rooms);
      });
    },
    initializeRooms(rooms) {
      return rooms.map((r) => {
        r.systems?.map((s) => {
          s.icon = require(`../assets/icons/Dashboard/${
            this.categories[s.name].icon
          }.png`);
          s.name = categories[s.name].name;
        });

        return {
          ...r,
          expanded: false,
          icon: require(`../assets/icons/room-types/${r.typeName.replaceAll(
            " ",
            "-"
          )}.png`),
        };
      });
    },
    getBySystems() {
      this.$store.dispatch("project/getProjectSummary", true).then((data) => {
        this.project = data.data;
        this.systems = this.initializeSystems(this.project.systems);
      });
    },
    initializeSystems(systems) {
      return systems.map((s) => {
        s.rooms.map((r) => {
          r.icon = require(`../assets/icons/room-types/${r.typeName.replaceAll(
            " ",
            "-"
          )}.png`);
        });

        s.icon = require(`../assets/icons/Dashboard/${
          this.categories[s.name].icon
        }.png`);
        s.name = this.categories[s.name].name;

        return {
          ...s,
          expanded: false,
        };
      });
    },
    deleteAnswers(answersIds) {
      this.$store
        .dispatch("dashboard/deleteAnswers", {
          answer_ids: answersIds,
        })
        .then(() => this.getProjectData());
    },
    editAnswer(questionIds) {
      this.limitedQuestionIds = questionIds;
      this.showOverlay = true;
    },
  },
};
</script>

<style scoped lang="scss">
.cart {
  &__sort {
    --inactive: var(--grey);
    color: var(--inactive);
    border: 2px solid var(--inactive);

    &-active {
      --inactive: var(--primary-light);
    }
  }

  &__room {
    border: 2px solid var(--neutral-light);
    border-radius: 0.5rem;
    transition: height 500ms linear;

    &__system {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 4fr 1fr 0.5fr;
      grid-template-areas:
        "icon name . price delete"
        ". answers . . .";
      gap: 0.5rem;
      color: var(--neutral-light);
      align-items: center;
      border: 3px solid var(--primary-dark);
      border-radius: 0.5rem;

      &__icon {
        grid-area: icon;
        height: 5rem;
        justify-self: center;
      }

      &__name {
        grid-area: name;
      }

      &__price {
        grid-area: price;
      }

      &__answers {
        grid-area: answers;
      }

      &__delete {
        grid-area: delete;
      }
    }
  }
}
</style>
